import React, {Component} from 'react';

class Header extends Component {
    render() {
        return (
            <header>
                <h1>G+J Medien GmbH Blogger Eingabe-Form</h1>
            </header>
        )
    }
}

export default Header;