import React, {Component} from 'react';

import {connect} from "react-redux";
import {API_URL, CREDENTIALS} from "../constants/index";
import {loginUser, logoutUser, sendData, saveChecksum, saveData} from "../actions/index";
import LoginBox from "./LoginBox";
import InputForm from "./InputForm";

function mapStateToProps(state: any) {

    return {checksum: state.checksum, username: state.username};
}

function mapDispatchToProps(dispatch: any) {
    return {
        loginUser: (credentials: any) => dispatch(loginUser(credentials)),
        logoutUser: (credentials: any) => dispatch(logoutUser()),
        saveChecksum: (checksum: any) => dispatch(saveChecksum(checksum)),
        sendData: (article: any) => dispatch(sendData(article)),
        saveData: (article: any) => dispatch(saveData(article))
    };
}

class ConnectedFormWrapper extends Component<any, any> {

    public constructor(props: any) {

        super(props);
        this.state = {
            checksum: props.checksum,
            username: props.username,
            hasLogin: (props.checksum !== null) ? true : false,
            title: props.title,
            body: props.body,
            teasertext: props.teasertext,
            lastModDate: '',
            formKey: 1,
            isUploaoding: false
        }

    }

    public componentDidMount(): void {

        this.refreshUser();
    }

    public render() {

        return (
            <div className="form">
                {this.state.hasLogin === false ? <LoginBox loginUser={this.loginUser}/>
                    :
                    <div>Status eingeloggt als "{this.state.username}" - <a onClick={() => this.logoutUser()}>Logout</a>
                    </div>}
                <hr/>

                {this.state.lastModDate !== '' &&
                <div>
                    <small>Zuletzt gesendet: [ {this.state.lastModDate} ] </small>
                    <button onClick={this.incrementFormKey}>Neuer Artikel</button>
                </div>
                }


                {this.state.hasLogin === true &&
                <InputForm
                    key={this.state.formKey}
                    title={this.state.title}
                    body={this.state.body}
                    saveData={this.saveData}
                    sendData={this.sendData}
                />
                }
                {this.state.isUploaoding &&
                <div style={{fontWeight: 'bold', fontSize: '120%'}}>Bitte warten Sie, die Daten werden übertragen</div>}

            </div>
        )
    }

    private beautifulTime(): string {

        const when = new Date();

        let minutes = when.getMinutes();
        let minutesDoubleDigit: string = String(minutes);
        if (minutesDoubleDigit.length < 2) {
            minutesDoubleDigit = "0" + minutesDoubleDigit;
        }
        let month = 1 + when.getMonth();

        let timePrintable: string = when.getHours() + ":"
            + minutesDoubleDigit
            + " "
            + when.getDate() + "."
            + month + "."
            + when.getFullYear();

        return timePrintable;

    }

    private incrementFormKey = () => {

        this.setState({
            lastModDate: '',
            formKey: this.state.formKey + 1
        });
    };

    private saveData = (title: string, body: string, teasertext: string, url: string) => {

        this.props.saveData({title: title, body: body, teasertext: teasertext, url: url});
    };

    private sendData = (title: string, body: string, teasertext: string, url: string,
                        teaserImage: string, ogImage: string, teaserCopyright: string, ogCopyright: string) => {

        if (typeof title == "undefined" || title == "" || body == ""
            || teasertext == "" || teaserImage == "" || (teaserImage != "" && teaserCopyright == "")
            || ogImage == "" || (ogImage != "" && ogCopyright == "")) {

            alert("Bitte alle Felder des Formulars ausfüllen");
            return;
        }

        this.props.sendData({title: title, body: body, url: url});

        this.setState({
            lastModDate: this.beautifulTime()
        });

        this.sendRequest(title, body, teasertext, url, teaserImage, ogImage, teaserCopyright, ogCopyright);
    };

    private loginUser = (username: string, password: string) => {

        this.props.loginUser({username: username, password: password});
        this.loginRequest(username, password);
    };

    private sendRequest(title: string, body: string, teasertext: string, url: string, teaserImage: string,
                        ogImage: string, teaserCopyright: string, ogCopyright: string) {

        this.setState({
            isUploaoding: true
        });
        let that = this;

        const bodyParts = JSON.stringify({
            job: 'insert',
            title: title,
            url: url,
            body: body,
            teasertext: teasertext,
            teaserImage: teaserImage,
            teaserCopyright: teaserCopyright,
            ogCopyright: ogCopyright,
            ogImage: ogImage,
            chk: this.state.checksum
        });

        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(CREDENTIALS)
            },
            body: bodyParts
        })
            .then(response => response.json())
            .then(data => {

                if (!data.return) {

                    alert("Ein Fehler ist aufgetreten, Sie wurden ausgeloggt.");
                    return;
                } else if (data.return == "done") {

                    alert("Artikel wurde erfolgreich übertragen.");
                }

                this.setState({
                    isUploaoding: false
                });
            });
    }

    private refreshUser() {

        if (this.state.checksum === null) {

            return;
        }

        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(CREDENTIALS)
            },
            body: JSON.stringify({
                job: 'reauthenticate',
                chk: this.state.checksum
            })
        })
            .then(response => response.json())
            .then(data => {

                if (data.return === false) {
                    this.logoutUser();
                }
            });
    };

    private loginRequest(username: string, password: string) {

        let that = this;
        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(CREDENTIALS)
            },
            body: JSON.stringify({
                job: 'authenticate',
                username: username,
                password: password
            })
        })
            .then(response => response.json())
            .then(data => {

                if (!data.return || data.return.indexOf('Sorry') !== -1) {

                    alert("Login nicht korrekt!");
                    return;
                }

                that.props.saveChecksum(data.return);
                that.setState({
                    checksum: data.return,
                    hasLogin: true,
                    username: username,
                    password: password
                })
            });
    };

    private logoutUser() {

        this.setState({
            checksum: null,
            username: null,
            hasLogin: false
        });
        this.props.logoutUser();
    };
}

const FormWrapper = connect(mapStateToProps, mapDispatchToProps)(ConnectedFormWrapper);
export default FormWrapper;