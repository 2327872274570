import {APP_NAME, LOGIN_USER, LOGOUT_USER, SAVE_CHECKSUM} from "../constants/index";

let username = JSON.parse(sessionStorage.getItem(APP_NAME + '-user'));
let checksum = JSON.parse(sessionStorage.getItem(APP_NAME + '-checksum'));
let title = JSON.parse(sessionStorage.getItem(APP_NAME + '-title'));
let body = JSON.parse(sessionStorage.getItem(APP_NAME + '-body'));

const initialState = {
    username: username ? username : null,
    checksum: checksum ? checksum : null,
    title: title ? title : null,
    body: body ? body : null,
};

function save(key, data) {

    sessionStorage.setItem(key, JSON.stringify(data));
}

function rootReducer(state = initialState, action) {
    if (action.type === LOGIN_USER) {

        let newState = Object.assign({}, state, {
            username: action.payload.username
        });

        save(APP_NAME + '-user', action.payload.username);
        return newState;
    } else if (action.type === LOGOUT_USER) {

        let newState = Object.assign({}, state, {
            username: null,
            checksum: null
        });

        save(APP_NAME + '-user', null);
        save(APP_NAME + '-checksum', null);
        return newState;
    } else if (action.type === SAVE_CHECKSUM) {
        let newState = Object.assign({}, state, {
            checksum: action.payload
        });

        save(APP_NAME + '-checksum', action.payload);
        return newState;
    // } else if (action.type === SEND_DATA) {
    //
    // } else if (action.type === SAVE_DATA) {

    // } else {
    //     console.log(action.type + "not caught");
    }

    return state;
}

export default rootReducer;