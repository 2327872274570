import React, {Component} from 'react';

class LoginBox extends Component<any, any> {

    public constructor(props: any) {

        super(props);

        this.state = {
            username: null,
            password: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return (
            <div>
                <div>
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        placeholder="Benutzernamen eingeben"
                        onChange={this.handleChange}
                    />
                </div>

                <div>
                    <label>Passwort</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Kennwort eingeben"
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <button onClick={() => {
                        this.props.loginUser(this.state.username, this.state.password)
                    }}>Login
                    </button>
                </div>
            </div>
        )
    }

    protected handleChange = (event: any) => {

        this.setState({
            [event.target.name]: event.target.value
        });
    }
}

export default LoginBox;