import React from 'react';
import { render } from "react-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import store from "./store/index";

render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
);
serviceWorker.unregister();