import React, {Component} from 'react';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class InputForm extends Component<any, any> {

    public constructor(props: any) {

        super(props);

        this.state = {
            title: props.title,
            body: props.body,
            teasertext: props.teasertext,
            url: props.url,
            teaserCopyright: '',
            ogCopyright: '',
            teaserImage: '',
            ogImage: '',
            editorState: (props.body) ? props.body : EditorState.createEmpty(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    public render() {

        const toolBarOptions = {
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
            inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
            },
            blockType: {
                inDropdown: true,
                options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
            },
        };

        return (
            <div>

                <div className={"form-element"}>
                    <label>Titel</label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Titel eingeben"
                        onChange={this.handleChange}
                        value={this.state.title}
                    />
                </div>

                <div className={"form-element"}>
                    <label>Url</label>
                    <input
                        type="text"
                        name="url"
                        placeholder="Url zum Original eingeben"
                        onChange={this.handleChange}
                        value={this.state.url}
                    />
                </div>

                <div className={"form-element"}>
                    <label>Teasertext (maximal 400 Zeichen)</label>
                    <textarea
                        name="teasertext"
                        maxLength={400}
                        onChange={this.handleChange}
                        value={this.state.teasertext}
                    />
                </div>

                <div className={"form-element"}>
                    <label>Body</label>
                    <Editor
                        editorState={this.state.editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        onContentStateChange={this.onContentStateChange}
                        toolbar={toolBarOptions}
                    />
                </div>


                <div className={"form-element"}>
                    <label>Teaserbild</label>
                    <div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={this.uploadFile}
                            name="teaserImage"
                        />
                    </div>
                    {this.state.teaserImage !== "" && <img src={this.state.teaserImage} height={100}/>}

                </div>

                <div className={"form-element"}>
                    <label>Teaser-Bild Copyright</label>
                    <input
                        type="text"
                        name="teaserCopyright"
                        placeholder="Copyrights eingeben"
                        onChange={this.handleChange}
                        value={this.state.teaserCopyright}
                    />
                </div>

                <div className={"form-element"}>
                    <label>Banner-Bild / OG-Bild</label>
                    <div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={this.uploadFile}
                            name="ogImage"
                        />
                    </div>
                    {this.state.ogImage !== "" && <img src={this.state.ogImage} height={100}/>}

                </div>
                <div className={"form-element"}>
                    <label>OG-Bild Copyright</label>
                    <input
                        type="text"
                        name="ogCopyright"
                        placeholder="Copyrights eingeben"
                        onChange={this.handleChange}
                        value={this.state.ogCopyright}
                    />
                </div>

                <div className={"form-element"}>

                    {this.formValidate() === true ?
                        <button onClick={() => this.send()}>Absenden
                        </button> : 'Bevor Sie absenden können, bitte zunächst Titel, Text und Bild anfügen'}
                </div>

            </div>
        )
    }

    protected uploadFile = (event: any) => {

        if (!event.target.files[0]) {
            return;
        }

        let picReader = new FileReader();
        let targetField = event.target.name;
        let that = this;

        picReader.onload = function () {
            that.fileReaderCallback(targetField, picReader.result);
        };
        picReader.readAsDataURL(event.target.files[0]);
    };

    protected handleChange = (event: any) => {

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.props.saveData(this.state.title, this.state.body, this.state.teasertext, this.state.url);
        });
    };

    private send = () => {

        this.props.sendData(this.state.title, this.state.body, this.state.teasertext, this.state.url,
            this.state.teaserImage, this.state.ogImage,
            this.state.teaserCopyright, this.state.ogCopyright
        );
    };

    private formValidate() {

        return this.state.title === '' || this.state.body === '' || this.state.teaserimage === ''
        || this.state.ogImage === ''
        || this.state.url === '' ? false : true;
    }

    private onContentStateChange = (editorState: any) => {

        let contents = Array();
        editorState.blocks.map(block => {

            const content = block.text;
            contents.push(`<p>${content}</p>`);
        });
        this.setState({
            body: contents.join(''),
        }, () => {
            this.props.saveData(this.state.title, this.state.body);
        });
    };

    private onEditorStateChange = (editorState: any) => {

        this.setState({
            editorState,
        });
    };

    private fileReaderCallback = (targetField: string, result: any) => {

        let that = this;

        if (targetField == 'teaserImage') {
            let img = document.createElement("img");
            img.setAttribute("src", result);
            setTimeout(function () {

                // if (img.width != 600 && img.height != 460) {
                //
                //     alert(`Die Maße des ausgewählten Bildes sind nicht korrekt (${img.width}x${img.height}). Bitte 600x460 Pixel`);
                // } else {

                    that.setState({
                        [targetField]: result
                    }, () => that.props.saveData(that.state.title, that.state.body, that.state.url));
                // }

            }, 0);
        } else if (targetField == 'ogImage') {
            let img = document.createElement("img");
            img.setAttribute("src", result);
            setTimeout(function () {

                // if (img.width != 900 && img.height != 500) {
                //
                //     alert(`Die Maße des ausgewählten Bildes sind nicht korrekt (${img.width}x${img.height}). Bitte 960x500 Pixel`);
                // } else {

                    that.setState({
                        [targetField]: result
                    }, () => that.props.saveData(that.state.title, that.state.body, that.state.url));
                // }

            }, 0);
        } else {

            that.setState({
                [targetField]: result
            }, () => that.props.saveData(that.state.title, that.state.body, that.state.url));
        }

    };

}

export default InputForm;