export const APP_NAME = "parenting-media-blogger";
export const LOGIN_USER = "LOGIN_USER";
export const SAVE_DATA = "SAVE_DATA";
export const SEND_DATA = "SEND_DATA";
export const SAVE_CHECKSUM = "SAVE_CHECKSUM";
export const LOGOUT_USER = "LOGOUT_USER";
export const CREDENTIALS = "blogger:61etASs5t0Q916S8";
export const API_URL = (document.location.href.indexOf('ohr-nach-draussen.eltern') !== -1)
    ? 'https://ohr-nach-draussen.eltern.de/api.php?'
    : (document.location.href.indexOf('blogger.vorname') !== -1)
        ? 'https://blogger.vorname.com/api.php?'
        : 'http://192.168.33.19/web/api.php?';

console.log('API_URL', API_URL);