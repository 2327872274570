import React, {Component} from 'react';
import Header from './components/Header';
import FormWrapper from './components/FormWrapper';

class App extends Component {

    render() {

        return (
            <div className="App">
                <Header/>
                <FormWrapper/>
            </div>
        );
    }
}

export default App;
