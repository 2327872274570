import {LOGIN_USER, LOGOUT_USER, SEND_DATA, SAVE_CHECKSUM, SAVE_DATA} from "../constants/index";

export function logoutUser(payload) {
    return {type: LOGOUT_USER, payload};
}

export function loginUser(payload) {
    return {type: LOGIN_USER, payload};
}

export function sendData(payload) {
    return {type: SEND_DATA, payload};
}

export function saveChecksum(payload) {
    return {type: SAVE_CHECKSUM, payload};
}

export function saveData(payload) {
    return {type: SAVE_DATA, payload};
}
